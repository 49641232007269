import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import HBImage from '@/components/packages/hbimage';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import classes from './mob-horizontal-scroll-new.module.scss';

const MobHorizontalScrollNew = ({
  data,
  onSelect,
  itemClass,
  innerWrapperClass,
  arrowLeft,
  arrowRight,
  value,
  arrowLeft1,
  arrowRight1,
  innerWrapperStyle,
  rightArrowStyle,
  leftArrowStyle,
}) => {
  const [displayedCrds, setDisplayedCrds] = useState(data.slice(0, 3)); // Start with 3 items
  const [firstItemRef, setFirstItemRef] = useState(null);
  const [, setActiveRef] = useState(null);
  const wrapperRef = useRef(null);

  // Function to handle resizing and update the displayed cards accordingly
  const handleResize = () => {
    if (window.innerWidth <= 1024) {
      setDisplayedCrds(data.slice(0, 3)); // Show only 3 items on smaller screens
    } else {
      setDisplayedCrds(data); // Show all items on larger screens
    }
  };

  useEffect(() => {
    handleResize(); // Initialize the displayed cards based on screen size
    
    window.addEventListener('resize', handleResize); // Add resize event listener

    // Cleanup: Remove the resize event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data]); // Re-run when `data` changes

  useEffect(() => {
    const handleScroll = () => {
      if (
        wrapperRef.current &&
        wrapperRef.current.scrollWidth - wrapperRef.current.scrollLeft <=
          wrapperRef.current.clientWidth + firstItemRef?.clientWidth
      ) {
        if (displayedCrds.length < data.length) {
          setDisplayedCrds(prevData => data.slice(0, prevData.length + 3));
        }
      }
    };

    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [displayedCrds, data, firstItemRef]);

  function onRightArrowClick() {
    wrapperRef.current?.scroll({
      top: 0,
      left: wrapperRef.current?.scrollLeft + firstItemRef.clientWidth,
      behavior: 'smooth',
    });
  }

  function onLeftArrowClick() {
    wrapperRef.current.scroll({
      top: 0,
      left: wrapperRef.current?.scrollLeft + firstItemRef.clientWidth * -1,
      behavior: 'smooth',
    });
  }

  return (
    <div className={classes.positionRelative}>
      <div
        ref={wrapperRef}
        style={{ ...innerWrapperStyle }}
        className={classNames([innerWrapperClass, classes.wrapper])}
      >
        {displayedCrds.map((item, i) => (
          <div
            ref={(ref) => {
              if (i === 0 && ref) setFirstItemRef(ref);
              else setActiveRef(ref);
            }}
            onClick={() => {
              if (onSelect) {
                onSelect(i);
              }
            }}
            key={i}
            className={classNames([itemClass, classes.item])}
          >
            {item}
          </div>
        ))}
        {arrowRight && (
          <div
            onClick={onRightArrowClick}
            className="nextico-our-culture"
            style={rightArrowStyle}
          >
            {value == 'ratingReview' ? (
              <HBImage
                width="25px"
                height="25px"
                src="/static/images/right-arrow-test.png"
                alt="right-arrow-test"
              />
            ) : (
              <HBImage
                width="20px"
                height="20px"
                src="/static/images/arrow-right1.png"
                alt="right-arrow-test"
              />
            )}
          </div>
        )}
        {arrowLeft && (
          <div
            onClick={onLeftArrowClick}
            className="previco-our-culture"
            style={leftArrowStyle}
          >
            {value == 'ratingReview' ? (
              <HBImage
                width="25px"
                height="25px"
                src="/static/images/left-arrow-test.png"
                alt="left-arrow-test"
              />
            ) : (
              <HBImage
                width="20px"
                height="20px"
                src="/static/images/arrow-left1.png"
                alt="left-arrow-test"
              />
            )}
          </div>
        )}

        {arrowRight1 && (
          <div onClick={onRightArrowClick} className="shortpost-next">
            <ChevronRightIcon />
          </div>
        )}
        {arrowLeft1 && (
          <div onClick={onLeftArrowClick} className="shortpost-prev">
            <ChevronLeftIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobHorizontalScrollNew;
