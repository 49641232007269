import React from 'react';
import Grid from '@mui/material/Grid2';
import { Box } from '@mui/material';
import whyDetails from '@/data/whyDetails.json';

const WhyInfoSection = () => {

const rootStyles = {
    width:'960px',
    marginLeft:'auto',
    marginRight:'auto',
    marginTop:'-30px',

    '@media (min-width: 768px) and (max-width: 768px)': {
      width:'100%',
    },
    '@media (min-width: 769px) and (max-width: 1025px)': {
      marginTop: '120px',
      width: '94%',
      },
    // '@media (min-width: 1024px) and (max-width: 1024px)': {
    // marginTop: '120px',
    // },

    //    '@media (min-width: 1025px) and (max-width: 1280px)': {
    // marginTop: '120px',
    // },

    '& .whyDetailsContainer' :{
      alignItems: 'center',
    borderRadius: '3px',
    display: 'flex',
    },
    '& .whyDetailsBox' :{
      borderRadius: '4px',
      padding: '12px',
    },
    '& .whyDetailsInnerBox' :{
      padding: '1px',

      '&.whyimg1':{
        '& img':{
          objectPosition: '-37px 0px',
          objectFit: 'cover',
          width: '55px',
          height: '38px',
          marginLeft:'-11px'
        }
      },

      '&.whyimg2':{
        '& img':{
          objectPosition: '-91px 0px',
          objectFit: 'cover',
          width: '55px',
          height: '38px'
        }
      },

      '&.whyimg3':{
        '& img':{
          objectPosition: '-147px 0px',
          objectFit: 'cover',
          width: '53px',
          height: '38px'
        }
      },

      '&.whyimg4':{
        '& img':{
          objectPosition: '-196px 0px',
          objectFit: 'cover',
          width: '55px',
          height: '38px'
        }
      },
  },
  '& .whyDetailsHeading':{
   '& h5':{
    fontSize:'15px',
    '@media (min-width: 767px) and (max-width: 1025px)': {
      fontSize:'13px',
    }
   },
  },
  '& .whyContent':{
    // width:'20.7%',
    display: 'flex',
    borderLeft: '1px solid rgba(199,197,197, 1)',
    '&:first-of-type': {
      borderLeft: 'none', // Removes border for the first .whyContent sibling
    },
    '& > :first-child': {
      // Apply this if you want to style the first child *inside* whyContent
      borderLeft: 'none',
    },
  },

  '& .whybox-border':{
    width:'81%',
    display: 'flex',
    '@media (min-width: 769px) and (max-width: 1025px)': {
      width:'79%',
    }
  },

  '& .whyLogo':{
    width:'15.6%',
    display: 'flex',
    justifyContent:'center',
    position:'relative',
    borderRight:'1px solid rgba(199,197,197, 1)',
    marginRight:'32px',
'@media (min-width: 767px) and (max-width: 1025px)': {
  width:'13.6%',
}
  },

  '& .whyimg':{
    position: 'absolute',
    top: '14px',
    right: '-14px',

    '& img':{
    objectPosition: '1px 0px',
    objectFit: 'cover',
    width: '14px',
    height: '38px',
    marginLeft:'-11px'
    }
  }
  }

  return (
<Box sx={{...rootStyles}}> 
  <Grid container size={12}>
    <Grid textAlign='center' className="whyLogo">  
                            <img  src='https://ik.imagekit.io/sjnshacs8/propertygallery/whylogo.png' alt="whyimg" title="whyimg" height="73px" width='111px' />
                             <div className='whyimg'><img  src='../static/images/whyiconimg.png' alt="icon" /></div> 
                            </Grid>  
<Grid className="whybox-border">
    {whyDetails &&
              whyDetails.map((data) => {
                const { id, title, className } = data;
                return (
                  (<Grid
                    key={id}
                    className="whyContent">
                    <Grid className="whyDetailsContainer">
                      <Grid className="whyDetailsBox">
                        <Grid className={`whyDetailsInnerBox ${className}`}>
                        <img  src='../static/images/whyiconimg.png' alt="icon" />
                        </Grid>
                      </Grid>
                      <Grid className="whyDetailsHeading">
                        <h5>{title}</h5>
                      </Grid>
                    </Grid>
                  </Grid>)
                );
              })}
              </Grid>
  </Grid>
    </Box>
  );
};

export default WhyInfoSection;
