/* ########## COMPONENT IMPORTS ########## */
import CommonTabs from '@/components/packages/common-tabs';
import EnquiryForm from '@/components/Desktop/Common/EnquiryForm';
import LoginAndResetPassword from '@/components/Desktop/Common/LoginAndResetPassword';
import ScheduleTourForm from '@/components/Desktop/Common/ScheduleTourForm';
import PopupDialogBox from '@/components/packages/popup-dialog-box';
import PropertyDetailsGallery from '@/components/packages/property-details-gallery';
import useLoginForm from '@/components/packages/use-login-form';
/* ########## REDUX IMPORTS ########## */
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import {
  getFooterData,
  getLeaseTypePropertyCount,
  setCurrentPageType,
} from '../../redux/actions/main';
import groupBy from 'lodash/groupBy';
import MobileGallery from '../packages/mobile-gallery';
import axios from 'axios';
import { API_ROOT } from '../../config';
import { GET_PROPERTY_GALLERY_API } from '../../config/EndpointHelper';
import {
  findClientIp,
  persistClientIp,
  logoutUser,
  getQueryParamFromUrl,
} from '../util';
import useWindow from '@/hooks/useWindow';
import MobileDevelopers from '../Mobile/Developers/BuilderCardVertical';
import NewProject from '../Desktop/Projects/NewProject';
import Livability from '../Desktop/Projects/Livability';
import RecommendProject from '../Desktop/Projects/RecommendProject';
import DesktopDevelopers from '../Desktop/DesktopBuilderCardVertical';
import MobLivability from '../Mobile/Projects/MobLivability';
import MobNewProject from '../Mobile/Projects/MobNewProject';
import MobRecommendProject from '../Mobile/Projects/MobRecommendProject';
import Testimonials from '../Desktop/Testimonials/Testimonials';
import MobRecentlyPost from '../Mobile/Projects/MobRecentlyPost';
import CompatibleCollection from '../Desktop/Projects/CompatibleCollection';
import RentalSection from '../Desktop/Projects/RentalSection';
import BlogPost from '../Desktop/Projects/BlogPost';
import RecentlyPost from '../Desktop/Projects/RecentlyPost';
import useUserInfo from '../packages/use-user-info';
import WhyInfoSection from '../Desktop/Projects/WhyInfoSection';
import MobWhyInfoSection from '../Mobile/Projects/MobWhyInfoSection' 

const PopupContainer = props => {
  const { isOpen, setOpen, content, isMobileView } = props;

  function closeModal() {
    setOpen(false);
  }
  return (
    <>
      {!isMobileView && (
        <PopupDialogBox
          style={{ height: '40px' }}
          root_class="custom-modal"
          open={isOpen}
          close={closeModal}
          modal_content={content}
        />
      )}
    </>
  );
};

const HomePageComponent = props => {
  const hasWindow = useWindow();
  persistClientIp(props);

  const {
    recentlyPostedData,
    dowData,
    discountDealData,
    // developerData,
    testimonialsData,
    blogsData,
    // footerData,
    city,
    module_type,
    // locations,
    // onCitySelect,
    // onModuleSelect,
    isMobileView,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    wishlistPropertyDeletedCall,
    callWishlistApi,
    // recommendProjectData,
    currentRoute
  } = props;
  const [user] = useUserInfo();
  const [is_open, setIsOpen] = useState(false);
  const [enquiry_form_heading, setEnquiryFormHeading] = useState('');
  const [property_id, setPropertyId] = useState('');
  const [priceRequest, setPriceRequest] = useState(true);
  const [areaRequest, setAreaRequest] = useState(true);
  const [is_login_open, openLoginModal, closeLoginModal] = useLoginForm();
  const [developer_name, setDeveloperName] = useState('');
  const [cachedProject, setCachedProject] = useState();
  const router = useRouter();
  const [deletecachedpropertytrigger, setDeleteCachedPropertyTrigger] =
    useState(false);
  function openModal(
    propertyid,
    property_name,
    developerName,
    price_request = false,
    area_request = false
  ) {
    setDeveloperName(developerName);
    setEnquiryFormHeading(property_name);
    setPropertyId(propertyid);
    setIsOpen(true);
    setAreaRequest(area_request);
    setPriceRequest(price_request);
  }
  const [showGallery, setShowGallery] = useState(false);
  const [gallery_open, setGalleryOpen] = useState(false);
  const [street_url, setStreetUrl] = useState('');
  const [video_url, setVideoUrl] = useState('');
  const [gallery_key, setGalleryKey] = useState('');

  let newProjectData = [];
  let rentProjectData = [];
  let resaleProjectData = [];
  // const [memberData, setMemberData] = useState([]);
  const [mobileviewData, setMobileviewData] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');

  const filterVideoAndStreet = data => {
    const filteredData = {
      Street: data.Street || [],
      Video: data.Video || [],
    };
    return filteredData;
  };
  const setPropertyDetailsGallery = async (
    gallerykey,
    streeturl,
    videourl,
    pId
  ) => {
    setGalleryKey(gallerykey);
    setStreetUrl(streeturl);
    setVideoUrl(videourl);
    const body = {
      p_id: pId,
    };
    let apiResponse = await axios.get(
      `${API_ROOT}${GET_PROPERTY_GALLERY_API}`,
      { params: body }
    );

    if (apiResponse.status === 200) {
      const data = apiResponse.data.data;

      let categoriesData =
        data &&
        data.images.map(image => {
          image.id = `image-${image.id}`;
          return { ...image, [`type`]: 'image' };
        });

      let galleryData = groupBy(categoriesData, 'category');
      if (data && data.video) {
        galleryData = {
          ...galleryData,
          Video: [{ id: 'video-1', type: 'video', url: data.video }],
        };
      }
      if (data && data.streetView) {
        galleryData = {
          ...galleryData,
          Street: [{ id: 'street-1', type: '360', url: data.streetView }],
        };
      }

      // Example usage:
      const filteredGalleryData = filterVideoAndStreet(galleryData);
      setMobileviewData(filteredGalleryData);
      // setMemberData(galleryData);
      setSelectedSection(gallerykey == 'VIDEO_VIEW' ? 'Video' : 'Street');
      isMobileView ? setShowGallery(true) : setGalleryOpen(true);
    }
  };
  const galleryClose = () => {
    isMobileView ? setShowGallery(false) : setGalleryOpen(false);
  };

  const getRouteParams = routePath => {
    let queryRouteParams = routePath?.split('?')[1]?.split('&');
    let paramsObj = {};
    queryRouteParams?.forEach(param => {
      let aParam = param.split('=');
      paramsObj[aParam[0]] = aParam[1];
    });
    return paramsObj;
  };
  const queryParams = getRouteParams(router.asPath);

  useEffect(() => {
    const logoutRequested =
      getQueryParamFromUrl(router.asPath, 'logout') == '1';
    if (logoutRequested) {
      // logout user
      logoutUser(false, hasWindow);
      // Redirect to home page
      router.replace('/');
    }
    if (queryParams.pparam == 'login' || queryParams.pparam == 'reset') {
      openLoginModal();
    }
  }, []);
  const getcachedApiData = () => {
    if (
      JSON.parse(localStorage.getItem('buy')) ||
      JSON.parse(localStorage.getItem('rent')) ||
      JSON.parse(localStorage.getItem('resale'))
    ) {
      newProjectData = JSON.parse(localStorage.getItem('buy'));
      rentProjectData = JSON.parse(localStorage.getItem('rent'));
      resaleProjectData = JSON.parse(localStorage.getItem('resale'));

      const wishlist = [
        {
          panelID: 'panel1',
          label: 'New Project',
          project: newProjectData ? newProjectData : [],
        },
        {
          panelID: 'panel2',
          label: 'Resale',
          project: resaleProjectData ? resaleProjectData : [],
        },
        {
          panelID: 'panel3',
          label: 'Rent',
          project: rentProjectData ? rentProjectData : [],
        },
      ];
      if (
        (newProjectData && newProjectData?.length > 0) ||
        (rentProjectData && rentProjectData?.length > 0) ||
        (resaleProjectData && resaleProjectData?.length > 0)
      ) {
        setCachedProject(wishlist);
      } else {
        setCachedProject([]);
      }
    }
  };

  useEffect(() => {
    getcachedApiData();
  }, [deletecachedpropertytrigger]);

  useEffect(() => {
    getcachedApiData();
  }, []);
  // @TO-DO : Adding key for conditional rendering of header
  // const pageName = 'HomePage';
  // useEffect(() => {
  //   if (module_type == 'rent') {
  //     props.getLeaseTypePropertyCount({
  //       module_type: module_type,
  //       city_id: city?.id,
  //     });
  //   }
  // }, [module_type, city]);

  return (
    <>
      {isMobileView ? (
        <>
          {module_type == 'buy' && (
            <>
            <MobWhyInfoSection/>
              <MobLivability
                data={dowData}
                city={city}
                open_modal={openModal}
                module_type={module_type}
                propertyDetailsGallery={setPropertyDetailsGallery}
                cachedProject={cachedProject}
                callWishlistApi={callWishlistApi}
                wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
                addwishlisttrigger={addwishlisttrigger}
                setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
                deletecachedpropertytrigger={deletecachedpropertytrigger}
                user={user}
                currentRoute={currentRoute}
              />
              <MobNewProject
                data={discountDealData}
                city={city}
                open_modal={openModal}
                module_type={module_type}
                propertyDetailsGallery={setPropertyDetailsGallery}
                cachedProject={cachedProject}
                callWishlistApi={callWishlistApi}
                wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
                addwishlisttrigger={addwishlisttrigger}
                setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
                deletecachedpropertytrigger={deletecachedpropertytrigger}
                user={user}
                currentRoute={currentRoute}
              />
              {/* {loadComponents && ( */}
              <>
                <MobRecommendProject
                  city={city}
                  open_modal={openModal}
                  module_type={module_type}
                  propertyDetailsGallery={setPropertyDetailsGallery}
                  cachedProject={cachedProject}
                  callWishlistApi={callWishlistApi}
                  wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                  wishlistpropertydeletedtrigger={
                    wishlistpropertydeletedtrigger
                  }
                  addwishlisttrigger={addwishlisttrigger}
                  setDeleteCachedPropertyTrigger={
                    setDeleteCachedPropertyTrigger
                  }
                  user={user}
                  deletecachedpropertytrigger={deletecachedpropertytrigger}
                  currentRoute={currentRoute}
                />

                {/* <BenifitsWithUs /> */}
                <MobileDevelopers data={null} city={city} user={user} currentRoute={currentRoute}/>

                {/* <DesignedFor /> */}
                <Testimonials data={testimonialsData} />
                {/* <HomeOwner /> */}
                {/* <WhyHB /> */}
                {/* <Assistance /> */}
              </>
              {/* )} */}
            </>
          )}

          {/* Rent Section */}
          {module_type == 'rent' && (
            <>
              <MobRecentlyPost
                // data={recentlyPostedData}
                data={
                  recentlyPostedData !== undefined
                    ? recentlyPostedData
                    : { projects: [], loading: false }
                }
                city={city}
                open_modal={openModal}
                module_type={module_type}
                propertyDetailsGallery={setPropertyDetailsGallery}
                cachedProject={cachedProject}
                callWishlistApi={callWishlistApi}
                wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
                addwishlisttrigger={addwishlisttrigger}
                setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
                deletecachedpropertytrigger={deletecachedpropertytrigger}
              />
              <CompatibleCollection
                city={city}
                open_modal={openModal}
                module_type={module_type}
                propertyDetailsGallery={setPropertyDetailsGallery}
                openLoginModal={openLoginModal}
                is_desktop={true}
                isMobileView={isMobileView}
                propertyCountdata={
                  props.leasePropertyCount.lease_property_count
                }
              />
              <RentalSection
                city={city}
                open_modal={openModal}
                module_type={module_type}
                propertyDetailsGallery={setPropertyDetailsGallery}
                openLoginModal={openLoginModal}
                is_desktop={true}
                isMobileView={isMobileView}
              />
            </>
          )}

          {/* {loadComponents && ( */}
          <BlogPost
            data={blogsData}
            city={city}
            open_modal={openModal}
            module_type={module_type}
            propertyDetailsGallery={setPropertyDetailsGallery}
            openLoginModal={openLoginModal}
            is_desktop={false}
            isMobileView={isMobileView}
            currentRoute={currentRoute}
          />
          {/* )} */}
        </>
      ) : (
        <>
          {/* Deal of the week page */}
          {module_type == 'buy' && (
            <>
              <WhyInfoSection/>
              <Livability
                data={{ projects: [], loading: false }}
                city={city}
                open_modal={openModal}
                module_type={module_type}
                propertyDetailsGallery={setPropertyDetailsGallery}
                openLoginModal={openLoginModal}
                cachedProject={cachedProject}
                callWishlistApi={callWishlistApi}
                wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
                addwishlisttrigger={addwishlisttrigger}
                setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
                deletecachedpropertytrigger={deletecachedpropertytrigger}
                user={user}
                currentRoute={currentRoute}
              />
              <NewProject
                city={city}
                open_modal={openModal}
                module_type={module_type}
                propertyDetailsGallery={setPropertyDetailsGallery}
                openLoginModal={openLoginModal}
                cachedProject={cachedProject}
                callWishlistApi={callWishlistApi}
                wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
                addwishlisttrigger={addwishlisttrigger}
                setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
                deletecachedpropertytrigger={deletecachedpropertytrigger}
                user={user}
                currentRoute={currentRoute}
              />

              {/* {loadComponents && ( */}
              <>
                <RecommendProject
                  city={city}
                  open_modal={openModal}
                  module_type={module_type}
                  propertyDetailsGallery={setPropertyDetailsGallery}
                  openLoginModal={openLoginModal}
                  cachedProject={cachedProject}
                  callWishlistApi={callWishlistApi}
                  wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                  wishlistpropertydeletedtrigger={
                    wishlistpropertydeletedtrigger
                  }
                  addwishlisttrigger={addwishlisttrigger}
                  setDeleteCachedPropertyTrigger={
                    setDeleteCachedPropertyTrigger
                  }
                  user={user}
                  currentRoute={currentRoute}
                  deletecachedpropertytrigger={deletecachedpropertytrigger}
                />
                <DesktopDevelopers data={null} city={city} user={user} currentRoute={currentRoute}/>
                <Testimonials data={testimonialsData} is_desktop={true} />
              </>
              {/* )} */}
            </>
          )}

          {/* Rent Section */}
          {module_type == 'rent' && (
            <>
              <RecentlyPost
                data={
                  recentlyPostedData !== undefined
                    ? recentlyPostedData
                    : { projects: [], loading: false }
                }
                city={city}
                open_modal={openModal}
                module_type={module_type}
                propertyDetailsGallery={setPropertyDetailsGallery}
                openLoginModal={openLoginModal}
                cachedProject={cachedProject}
                callWishlistApi={callWishlistApi}
                wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
                addwishlisttrigger={addwishlisttrigger}
                setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
                deletecachedpropertytrigger={deletecachedpropertytrigger}
              />
              <CompatibleCollection
                city={city}
                open_modal={openModal}
                module_type={module_type}
                propertyDetailsGallery={setPropertyDetailsGallery}
                openLoginModal={openLoginModal}
                is_desktop={true}
                isMobileView={isMobileView}
                propertyCountdata={
                  props.leasePropertyCount.lease_property_count
                }
              />
              <RentalSection
                city={city}
                open_modal={openModal}
                module_type={module_type}
                propertyDetailsGallery={setPropertyDetailsGallery}
                openLoginModal={openLoginModal}
                is_desktop={true}
                isMobileView={isMobileView}
              />
            </>
          )}

          {/* {loadComponents && ( */}
          <BlogPost
            data={blogsData}
            city={city}
            open_modal={openModal}
            module_type={module_type}
            propertyDetailsGallery={setPropertyDetailsGallery}
            openLoginModal={openLoginModal}
            is_desktop={true}
            isMobileView={isMobileView}
            currentRoute={currentRoute}
          />
          {/* )} */}
        </>
      )}

      {is_open && (
        <Grid alignitems="center" direction="column" justify="center">
          <PopupContainer
            isOpen={is_open}
            setOpen={setIsOpen}
            snapPoints={[470, 300, 200, 0]}
            heading={enquiry_form_heading}
            subheading={developer_name}
            content={
              <CommonTabs
                active_index={0}
                style={{
                  height: '20px',
                }}
                list={[
                  {
                    label: priceRequest
                      ? 'Price On Request'
                      : areaRequest
                      ? 'Area On Request'
                      : 'Instant Call Back',
                    value: (
                      <EnquiryForm
                        property_id={property_id}
                        module_type={module_type}
                        closePopup={() => setIsOpen(false)}
                        heading={
                          priceRequest
                            ? 'Price On Request'
                            : areaRequest
                            ? 'Area On Request'
                            : 'Get Instant Call Back Now'
                        }
                        buttonHeading={
                          priceRequest
                            ? 'Request for Price'
                            : areaRequest
                            ? 'Request for Area'
                            : 'Get Instant Call Back'
                        }
                      />
                    ),
                  },
                  {
                    label: 'Arrange Site Visit',
                    value: (
                      <ScheduleTourForm
                        property_id={property_id}
                        heading={enquiry_form_heading}
                        module_type={module_type}
                        closePopup={() => setIsOpen(false)}
                      />
                    ),
                  },
                ]}
              />
            }
          />
        </Grid>
      )}

      {gallery_open && (
        <PropertyDetailsGallery
          handleClose={galleryClose}
          street_view={street_url}
          open={gallery_open}
          gallery_key={gallery_key}
          video_url={video_url}
        />
      )}

      {is_login_open && (
        <PopupDialogBox
          root_class="custom-modal"
          open={is_login_open}
          close={closeLoginModal}
          modal_content={
            <LoginAndResetPassword
              displayView={queryParams.pparam}
              tokenId={queryParams.t}
              closeModel={closeLoginModal}
            />
          }
        />
      )}

      {showGallery && (
        <MobileGallery
          title="Gallery"
          data={mobileviewData}
          onCloseClick={() => setShowGallery(!showGallery)}
          selectedSection={selectedSection}
        />
      )}
    </>
  );
};

export const getHomePageData = async ({ ctx }) => {
  const clientIp = findClientIp(ctx.req);

  return { props: { clientIp } };
};
const mapStateToProps = state => ({
  apiData: state.footerReducer,
  headersApiData: state.headerReducer,
  leasePropertyCount: state.LeastPropCountReducer,
});
const mapDispatchToProps = {
  getFooterData,
  getLeaseTypePropertyCount,
  setCurrentPageType,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageComponent);
