import React from 'react';
// import useMaterialMediaQuery from '../../packages/use-material-media-query';
import Grid from '@mui/material/Grid2';
import HorizontalScroll from '../../packages/horizontal-scroll';
import CommonHeaderText from '../../packages/common-header-text';
import HBImage from '../../packages/hbimage';
import mobrental_section from '../../../data/mobrental_section.json';
import rental_section from '../../../data/rental_section.json';
import Link from 'next/link';
import useAccessToken from '../../packages/use-access-token';
import { MYACCOUNT_HOMEBAZAAR } from '../../../config';
import Variables from 'styles/variables.module.scss';
import { slugifyURL } from '@/components/util';

const RentalSection = props => {
  const { city, module_type, isMobileView } = props;
  // console.log(isMobileView, isMobileView);
  // const [small] = useMaterialMediaQuery();
  const [access_token] = useAccessToken();
  const redirectToSearch = furnishType => {
    let url = `/${module_type}/${furnishType.toLowerCase()}-properties-in-${city?.name.toLowerCase()}`;

    window.location.href = url;
  };

  return (
    (<section className="main-section-rental">
      <div className="container">
        <Grid container>
          {isMobileView ? (
            <Grid className="rentalBannerMob">
              <Grid xd={12} container className="rentalInner">
                <Grid xd={12}>
                  {' '}
                  <CommonHeaderText
                    text="Rent your property for free"
                    variant="h4"
                    style={{
                      color: Variables.primaryColor,
                      fontSize: '18px',
                      fontFamily: 'Open Sans',
                      marginBottom: '8px',
                      whiteSpace: 'break-spaces',
                      fontWeight: 'bold',
                      padding: ' 20px 29px 2px',
                      letterSpacing: 'normal',
                    }}
                  />
                  <CommonHeaderText
                    text="Post your residential / commercial property for free as per the no brokerage policy"
                    variant="h6"
                    style={{
                      color: '#000000',
                      fontSize: '13px',
                      fontFamily: 'Open Sans',
                      fontWeight: '400',
                      marginBottom: '5px',
                      padding: ' 0px 29px',
                      letterSpacing: 'normal',
                    }}
                  />
                  <div className="postPropertyBtn">
                    <Link
                      href={
                        access_token
                          ? `${MYACCOUNT_HOMEBAZAAR}/properties/add?category=rent`
                          : '/rent-a-property'
                      }
                      target={'_blank'}
                      style={{
                        cursor: 'pointer',
                        fontSize: '13px',
                        background: Variables.primaryColor,
                        color: '#FFFFFF',
                        borderRadius: '5px',
                        width: '100%',
                        fontWeight: 'bold',
                      }}>
                      Post your property
                    </Link>
                  </div>
                </Grid>
                <Grid className="rentalMobImg" size={12}>
                  {/* <HBImage
                          width="413px"
                          height="111px"
                          src='/static/images/rental-collection/img11.png'
                          alt="customers-img"
                          title="customers-img"
                        /> */}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid className="rentalBanner">
              <Grid xd={12} container>
                <Grid
                  xd={12}
                  size={{
                    lg: 8,
                    md:6
                  }}>
                  {' '}
                  <CommonHeaderText
                    text="Rent your property for free"
                    variant="h4"
                    style={{
                      color: Variables.primaryColor,
                      fontSize: '26px',
                      fontFamily: 'Open Sans',
                      marginBottom: '5px',
                      whiteSpace: 'break-spaces',
                      fontWeight: 'bold',
                      padding: ' 20px 29px 2px',
                      letterSpacing: 'normal',
                    }}
                  />
                  <CommonHeaderText
                    text="Post your residential / commercial property for free as per the no brokerage policy"
                    variant="p"
                    style={{
                      color: '#000000',
                      fontSize: '14px',
                      fontFamily: 'Open Sans',
                      marginBottom: '5px',
                      whiteSpace: 'break-spaces',
                      fontWeight: 'bold',
                      padding: ' 0px 29px',
                      letterSpacing: 'normal',
                    }}
                  />
                  <div className="postPropertyBtn">
                    <Link
                      href={
                        access_token
                          ? `${MYACCOUNT_HOMEBAZAAR}/properties/add?category=rent`
                          : '/rent-a-property'
                      }
                      target="_blank"
                      style={{
                        cursor: 'pointer',
                        fontSize: '14px',
                        background: Variables.primaryColor,
                        color: '#FFFFFF',
                        padding: '12px 26px',
                        borderRadius: '5px',
                        fontWeight: 'bold',
                      }}>
                      Post your property
                    </Link>
                  </div>
                </Grid>
                <Grid
                className="rentSectionImg"
                  xd={12}
                  size={{
                    lg: 4,
                    md: 6,
                  }}
                  style={{ zIndex:'-1'}}>
                  <HBImage
                    width="413px"
                    height="111px"
                    src="/static/images/rental-collection/rentalbanner.png"
                    alt="customers-img"
                    title="customers-img"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container className="card-section">
          {isMobileView ? (
            <>
              <Grid size={12}>
                {mobrental_section &&
                  mobrental_section.map((data, index) => {
                    const { images, subheading } = data;
                    let url = slugifyURL(
                      `/${module_type}/${subheading.toLowerCase()}-properties-in-${city?.name.toLowerCase()}`
                    );
                    return (
                      <div
                        className="RentalSection"
                        key={index}
                        onClick={() => redirectToSearch(subheading)}>
                        <a
                          href={url}
                          // target="_blank"
                          rel="noreferrer"
                          className="card-link">
                          <div className="mobrentalImages1">
                            <HBImage
                              width="370px"
                              height="69px"
                              src={images}
                              alt="customers-img"
                              title="customers-img"
                            />
                          </div>
                          <div>
                            <Grid>
                              <>
                                <div className="rentalSubTextMob">
                                  {subheading}
                                </div>
                                <div className="btnExplore">
                                  {' '}
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '13px',
                                      background: Variables.primaryColor,
                                      color: '#FFFFFF',
                                      fontWeight: 'bold',
                                    }}>
                                    Explore Now
                                  </span>
                                </div>
                              </>
                            </Grid>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </Grid>
            </>
          ) : (
            <Grid spacing={2} size={12}>
              <HorizontalScroll
                itemClass=" MuiGrid-root MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-sm-6 MuiGrid-grid-md-4 MuiGrid-grid-lg-4"
                inertiaScrolling={true}
                inertiaScrollingSlowdown={0.5}
                // componentRef={componentRef}
                data={
                  rental_section &&
                  rental_section.map((data, index) => {
                    const { images, subheading } = data;
                    let url = slugifyURL(
                      `/${module_type}/${subheading.toLowerCase()}-properties-in-${city?.name.toLowerCase()}`
                    );
                    return (
                      <div
                        className="RentalSection"
                        key={index}
                        onClick={() => redirectToSearch(subheading)}>
                        <a
                          href={url}
                          // target="_blank"
                          rel="noreferrer"
                          className="card-link">
                          <div className="rentalImages1">
                            <HBImage
                              width="370px"
                              height="210px"
                              src={images}
                              alt="customers-img"
                              title="customers-img"
                            />
                          </div>
                          <div className="rentalSubText">
                            <Grid
                              style={{
                                color: '#2B2B2B',
                                fontSize: '18px',
                                fontFamily: 'Open Sans',
                                fontWeight: 'bold',
                              }}>
                              <>
                                <div>{subheading}</div>
                                <div className="btnExplore">
                                  {' '}
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '14px',
                                      background: Variables.primaryColor,
                                      color: '#FFFFFF',
                                      fontWeight: 'bold',
                                    }}>
                                    Explore Now
                                  </span>
                                </div>
                              </>
                            </Grid>
                          </div>
                        </a>
                      </div>
                    );
                  })
                }
                innerWrapperClass="PropertyBlogItem"
              />
            </Grid>
          )}
        </Grid>
      </div>
    </section>)
  );
};

export default RentalSection;
