import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import allcitiesData from '@/data/allcitiesData.json';

const rootStyle = {
  display: 'flex',
  paddingLeft: '9px',
  marginTop: '0px',
  '@media (min-width: 769px) and (max-width: 1114px)': {
    paddingLeft: '0px',
    // marginTop:'-4px',
  },

  '& li': {
    listStyle: 'none',
    padding: '2px 6px',
    fontSize: '16px',
    cursor: 'pointer',
    '@media (min-width: 769px) and (max-width: 1025px)': {
      fontSize:'15px',
    },
    '@media (min-width: 900px) and (max-width: 1025px)': {
     marginTop:'-5px',
    },

    '&:hover': {
      color: '#0056B2',
    },
    '&:focus': {
      color: '#0056B2',
    },
  },
  '& .active': {
    color: '#0056B2',
    fontWeight: 'bold',
  },
  
};

const HomepageCityTab = ({oncitySelect, globalCity}) => {
  const [selectedCity, setSelectedCity] = useState(globalCity?.name ?? 'mumbai');
  const handleCitySelect = (city) => {
    setSelectedCity(city.name);
    oncitySelect(city);
  };
  useEffect(() => {
    setSelectedCity(globalCity?.name ?? 'mumbai');
  }, [globalCity]);
  return (
    <Grid sx={{ padding: '1px 0' }}>
      <Grid container sx={{ ...rootStyle }}>
        {allcitiesData.map((city, index) => (
          <li
            key={index}
            role="listitem"
            className={selectedCity.toLowerCase() === city.name.toLowerCase() ? 'active' : ''}
            onClick={() => handleCitySelect(city)} // Use the city's name for selection
          >
            {city.name}
          </li>
        ))}
      </Grid>
    </Grid>
  );
};

export default HomepageCityTab;
