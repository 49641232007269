import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { Select, MenuItem, FormControl } from "@mui/material";
import allcitiesData from '@/data/allcitiesData.json';

const rootStyle = {
  paddingRight: "10px",
  width:'100%',

  '& .MuiInputBase-root': {
    paddingTop: '0px',
  },
  '& .MuiSelect-select': {
    paddingTop: '0px',
    paddingBottom: '0px',
    overflow :'hidden',
    paddingLeft:'6px',
    marginTop:'-1px',

  },
  '& .MuiList-root' :{
    paddingTop: '0px',
    paddingBottom: '0px',
  },

  '& .MuiMenuItem-root':{
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  '& .MuiButton-root' :{
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  '& .MuiButtonBase-root ':{
    fontSize:'15px',
  } 
};

const MobCityDropdown = ({oncitySelect=()=>{}, globalCity}) => {
  const [selectedCity, setSelectedCity] = useState(globalCity?.name.toLowerCase() ?? 'all');
  const handleCitySelect = (city) => {
    let cityData = allcitiesData.find(o => o.name.toLowerCase() == city.toLowerCase());
    setSelectedCity(city.toLowerCase());
    oncitySelect(cityData);
  };
  useEffect(() => {
    setSelectedCity(globalCity?.name.toLowerCase() ?? 'all');
  }, [globalCity]);

  return (
    <Grid container sx={{ ...rootStyle }}>
      <Grid size={12}>
        <FormControl style={{width:'100%'}}>
          <Select
            labelId="city-dropdown-label"
            value={selectedCity} // Controlled by state
            onChange={(e)=>handleCitySelect(e.target.value)}
            sx={{
                width: "100%",
                border: "none",
                paddingTop:'0px',
                color:'rgb(205, 75, 13)',
                fontWeight: '700',
                marginTop:'1px',
                lineHeight:'23px',
                fontSize:'17px',
                fontFamily:'Open Sans,sans-serif !important',
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Removes the outline border
                }
              }}
              MenuProps={{
              PaperProps: {
           sx: {
          "& ul": {
          padding: 0, // Removes padding from the `ul`
        },
      },
    },
  }}
>
            {allcitiesData.map((city) => (
              <MenuItem key={city.id} value={city.name.toLowerCase()}
              sx={{
                minHeight: "40px",
                paddingBottom:'0px',
                paddingTop:'0px',
                fontSize:'14px',
              }}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default MobCityDropdown;
