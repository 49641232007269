import React, { useEffect, useState } from 'react';
import handleViewport from 'react-in-viewport';
import { getDevelopers } from '../../../redux/actions/main';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../../packages/common-header-text';
import MobDeveloperCard from '../../packages/mob-developers-card';
import ViewAll from '../../Desktop/Common/ViewAll';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { capitalizeFirstLetter, slugifyURL } from '../../util';
import ClassNames from 'classnames';
// import MobHorizontalScroll from '../../packages/mob-horizontal-scroll';
import MobHorizontalScrollNew from '@/components/packages/mob-horizontal-scroll-new';
import styles from './mobileBuilder.module.scss';
import MobCityDropdown from '../Projects/MobCityDropdown';

const readMoreText = (charLimit, developer_desc) => {
  let shortText = '';
  if (developer_desc) {
    shortText =
      developer_desc
        .substr(0, charLimit)
        .replace(/[\s\n]+$/, '')
        // eslint-disable-next-line
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/, '') + '...';
  }
  return shortText;
};

const DevelopersSection = (props = { inViewport: false }) => {
  const { data, forwardedRef, city: cityData, currentRoute } = props; //inViewport
  const [city, setCity] = useState(cityData);

  const { developers } = props.apiData ?? data;

  const [, setDeveloperName] = useState('');
  const [, setDeveloperDesc] = useState('');
  const [, setDeveloperExperience] = useState('');
  const [, setDeveloperProjectListed] = useState('');

  // const classes = useStyles();

  useEffect(() => {
    props.getDevelopers({
      skip: 0,
      limit: 6,
      location: city?.name,
    });
  }, [city]);
  useEffect(() => {
    setCity(cityData);
  }, [cityData]);

  // All items component
  // Important! add unique key
  const Menu = (list, selectedNew) =>
    list.map((el, index) => {
      return <MenuItem text={el} key={index} selected={selectedNew} />;
    });

  const MenuItem = ({ text, selectedBuilder }) => {
    const {
      developer_name,
      logo,
      rating,
      id,
      description: developer_desc,
      experience,
      projects_listed,
    } = text;

    const buildersURL = slugifyURL(
      `/builders/${developer_name
        .replace(/\s/g, '-')
        .replace(/&/g, '-and-')
        .toLowerCase()}`
    );

    return (
      <>
        <MobDeveloperCard
          redirectURL={buildersURL}
          selected={selectedBuilder}
          id={id}
          developer_desc={developer_desc}
          developer_body={
            <Grid container style={{width:'100%'}}>
              <Grid size={12}>
                <Card className={styles.cardRoot}>
                  <CardContent
                    className={styles.cardSubHeader}
                    style={{ padding: '0px !important' }}>
                    <Grid container>
                      <Grid className={styles.borderRight} size={6}>
                        <h4 className={styles.expinYears}>
                          <span className={styles.fwBold}>
                            {experience || 0} yrs{' '}
                          </span>
                          Experience {' '}
                        </h4>
                      </Grid>
                      <Grid className={styles.borderLeft} size={6}>
                        <h4 className={styles.expinYears}>
                          <span className={styles.fwBold}>
                            {projects_listed || 0}{' '}
                          </span>
                          Projects{' '}
                        </h4>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent className={styles.cardInfo}>
                    <CommonHeaderText
                      className={styles.expinYears}
                      text={
                        <div>
                          {/* <p>{readMoreText(120, developer_desc)} </p> */}
                          <p>{readMoreText(100, developer_desc)} </p>
                          <a href={buildersURL}>
                            Read more about the Developer
                          </a>
                        </div>
                      }
                      variant="h5"
                    />
                  </CardContent>
                  <CardContent className={styles.cardFooter}>
                    <div className={styles.viwallText}>
                      <ViewAll
                        className={styles.textWhite}
                        text="View all Projects"
                        url={buildersURL}
                      />
                      <a href={buildersURL} aria-label="viewallproject">
                        <ArrowRightAltIcon className={styles.rightIcon} />
                      </a>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          }
          developer_name={<h4 className={styles.devName}>{developer_name}</h4>}
          developer_logo={logo}
          star_rating={rating}
        />
      </>
    );
  };

  const [selected] = useState('');
  const firstmenu = Menu(developers, selected);

  const onSelect = e => {
    let developer = searchDeveloper(e, developers);
    setDeveloperName(developer?.developer_name);
    setDeveloperDesc(developer?.description);
    setDeveloperExperience(developer?.experience);
    setDeveloperProjectListed(developer?.projects_listed);
  };

  const searchDeveloper = (nameKey, myArray) => {
    for (let i = 0; i < myArray.length; i++) {
      if (i == nameKey) {
        return myArray[i];
      }
    }
  };
  const highlightedWord = word => {
    return <span className={styles.dealWeekTitle}>{word}</span>;
  };
  // const highlightedWordBlue = word => {
  //   return <span>{word}</span>;
  // };
  const handleCitySelect = city => {
    setCity(city);
  };
  return developers.length !== 0 ? (
    <section
      className={ClassNames([styles.mainBuildersec, 'main-section'])}
      ref={forwardedRef}>
       <Grid className="container">
      <Grid
        justifyContent="space-between"
        container
        alignItems="flex-start"
        paddingtop="16px"
        className={ClassNames(
          ['heading-section', 'headerSpace'],
          'titleBottom'
        )}>
        <Grid sx={{ display: 'flex', gap: 0 }}>
          <Grid
            style={{ paddingTop: '0px' }}
            className={styles.headerTopbuilder}>
            <CommonHeaderText
              variant="h2"
              text={
                <>
                  {highlightedWord(
                    currentRoute === '/'
                      ? 'Top Builders '
                      : `Top Builders in ${capitalizeFirstLetter(
                          city?.name || ''
                        )}`
                  )}
                  {/* {highlightedWord('Top Builders  ' + (city?.name.toLowerCase() === 'all' ? '' : 'in ' + capitalizeFirstLetter(city?.name)))} */}
                  {/* {highlightedWord(
                  'Top Builders ' + capitalizeFirstLetter(city?.name)
                )} */}
                  {/* <span className={styles.cityTitle}>
                  in {highlightedWordBlue(capitalizeFirstLetter(city?.name))}
                </span> */}
                </>
              }
            />
            <p className={styles.cityName} style={{ marginBottom: '10px' }}>
              {/* {'Most Credible Developers in ' + capitalizeFirstLetter(city?.name)} */}
              {currentRoute === '/'
                ? 'Most Credible Developers'
                : `Most Credible Developers in ${capitalizeFirstLetter(
                    city?.name || ''
                  )}`}
            </p>
          </Grid>
          {currentRoute == '/' ? (
            <Grid style={{ paddingTop: '0px', marginLeft: '-51px' }}>
              <MobCityDropdown
                oncitySelect={handleCitySelect}
                globalCity={city}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        {city?.name.toLowerCase() === 'all' ? (
          ' '
        ) : (
          <Grid style={{ paddingTop: '0px' }}>
            <ViewAll
              text="View All"
              url={`/builders-in-${city?.name.toLowerCase()}`}
            />
          </Grid>
        )}
      </Grid>

      <div
        className={ClassNames(['card-section', 'cardBuilder'])}
        style={{
          position: 'relative',
        }}>
        <MobHorizontalScrollNew
          onSelect={onSelect}
          data={firstmenu}
          itemClass="blog-card-item MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"
        />
      </div>
      </Grid> 
    </section>
  ) : (
    ''
  );
};

const Developers = handleViewport(DevelopersSection, { rootMargin: '-1.0px' });

const mapStateToProps = state => ({
  apiData: state.developersReducer,
  headersApiData: state.headerReducer,
});

const mapDispatchToProps = {
  getDevelopers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Developers);
