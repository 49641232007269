/* ########## COMPONENT IMPORTS ########## */
// import PropertyCardStarRatingMaterial from '@/components/packages/property-card-star-rating-material';
import CommonCardContainer from '@/components/packages/common-card-container';
import { styled } from '@mui/material/styles';
import SessionStorage from '@/components/Desktop/Common/Wishlist/SessionStorage';
import useAccessToken from '@/components/packages/use-access-token';
import MobPropertyCardStarRatingMaterial from '../mob-property-card-star-rating-material';

/* ########## MATERIAL UI IMPORTS ########## */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import { Tooltip } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import ClassNames from 'classnames';
import debounce from 'lodash/debounce';
import ImagesSlider from '../images-slider';
import { getPropertyDetailsURL } from '@/components/util';
// import Variables from 'styles/variables.module.scss';
import classes from './mobResidentialProjectCard.module.scss';

const PREFIX = 'MobResidentialProjectCard';

const custom_class = {
  tooltip: `${PREFIX}-tooltip`,
  arrow: `${PREFIX}-arrow`
};

const StyledCommonCardContainer = styled(CommonCardContainer)({
  [`& .${custom_class.tooltip}`]: {
    color: '#171717',
    backgroundColor: '#fff',
    border: '1px solid #D3E0ED',
    marginBottom: '10px',
    width: '150px',
    fontSize: '13px!important',
    padding: '10px !important',
    fontWeight: '700!important',
    fontFamily: 'Open Sans, sans-serif !important',
    marginTop: '0!important',
  },
  [`& .${custom_class.arrow}`]: {
    '&:before': {
      border: '1px solid #D3E0ED',
    },
    color: '#fff',
  },
});

const MobResidentialProjectCard = props => {
  const {
    cardIndex,
    title,
    details,
    bhk,
    priceTag,
    Images,
    street_url,
    video_url,
    sqft,
    star_rating,
    property_id,
    property_name,
    developer_name,
    livability_index,
    investment_index,
    // propertyDetailsClick,
    scheduleTourClick,
    propertyDetailsGallery,
    module_type,
    cityName,
    wishlists,
    dealOffer,
    offer,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    toggleWishlist,
    projectName,
    bhk_types,
    priceToshow,
    p_we_recommend_count,
    location,
    minPrice,
    maxPrice,
    squarFit,
    minCarpetArea,
    furnishingStatus,
    maxCarpetArea,
    isResale,
  } = props;

  const [access_token] = useAccessToken();
  const [wishlist_flag, setWishlistFlag] = useState(wishlists);
  const propertyURL = getPropertyDetailsURL({
    module_type,
    city_name: cityName,
    property_name,
    property_id,
    location,
    bhkTypes: '',
    isResale: false,
  });

  const MoreDetailTooltip = Tooltip;
  const [dealPopup, setDealPopup] = useState(false);
  const [offerPopup, setOfferPopup] = useState(false);
  const dealTooltipRef = useRef();

  const imgSlider = useRef();
  React.useEffect(() => {
    if (imgSlider.current) {
      let btnElements = imgSlider.current.querySelectorAll('button');
      btnElements.forEach(element => element.classList.add('cardNotClicked'));
    }
  }, []);
  const setWishlistFlagFunc = debounce(propertyid => {
    toggleWishlist(propertyid);
    setWishlistFlag(!wishlist_flag);
  });
  const saveDatainSession = (
    investment_index_save,
    cityNameSave,
    property_id_save,
    projectNameSave,
    bhk_types_save,
    developer_name_save,
    livability_index_save,
    location_save,
    module_type_save,
    images,
    priceToshowSave,
    street_url_save,
    video_url_save,
    p_we_recommend_count_save,
    sqft_save,
    minPrice_save,
    maxPrice_save,
    minCarpetAreaSave,
    furnishingStatusSave
  ) => {
    SessionStorage({
      investment_index_save,
      cityNameSave,
      property_id_save,
      projectNameSave,
      buildingName: '',
      bhk_types_save,
      furnishStatus_save: '',
      developer_name_save,
      livability_index_save,
      location_save,
      module_type_save,
      images,
      priceToshowSave,
      street_url_save,
      video_url_save,
      p_we_recommend_count_save,
      sqft_save,
      minPrice_save,
      maxPrice_save,
      minCarpetAreaSave,
      furnishingStatusSave,
      maxCarpetArea,
      isResale,
    });
    setWishlistFlag(!wishlist_flag);
    setDeleteCachedPropertyTrigger(!deletecachedpropertytrigger);
  };

  useEffect(() => {
    if (access_token && access_token != '') {
      setWishlistFlag(wishlists);
    } else {
      setWishlistFlag(0);
      cachedProject &&
        cachedProject.map(data => {
          const { project } = data;
          project &&
            project.map(dataCached => {
              const { propertyId } = dataCached;

              if (propertyId == property_id) {
                setWishlistFlag(1);
              }
            });
        });
    }

    return;
  }, [
    wishlists,
    cachedProject,
    access_token,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    property_id,
  ]);
  const croppedImage = Images.map(imageURL => `${imageURL}`);
  return (
    (<StyledCommonCardContainer url={propertyURL} target="_self">
      <Card className={ClassNames([classes.root, 'mob-property-card'])}>
        <CardContent style={{ padding: '0px!important' }}>
          <div ref={imgSlider} className={ClassNames(classes.cardTop)}>
            <ImagesSlider
              isFirstSearchCard={cardIndex === 0}
              images={croppedImage}
              projectName={property_name}
              sliderbtnClass={'cardNotClicked'}
              width={300}
              height={125}
            />
            <Grid
              className={classes.cardTopIcons}
              container
              justifyContent="space-between"
              >
              <Grid size={6}>
                <MobPropertyCardStarRatingMaterial
                  rating={star_rating}
                  precision={0.5}
                  root_class={classes.iconFilled}
                  emptyIcon={classes.iconBorder}
                />
              </Grid>
              <Grid size={6}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  {street_url && street_url != '' && (
                    <Grid
                      style={{
                        cursor: 'pointer',
                        marginBottom: '-15px',
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        propertyDetailsGallery(
                          'STREET_VIEW',
                          street_url,
                          video_url,
                          property_id
                        );
                      }}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="Street View"
                        style={{
                          objectPosition: '-344px 0px',
                          objectFit: 'cover',
                          width: '30px',
                          height: '38px',
                        }}
                      />
                    </Grid>
                  )}
                  {video_url && video_url != '' && (
                    <Grid
                      style={{
                        cursor: 'pointer',
                        marginBottom: '-15px',
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        propertyDetailsGallery(
                          'VIDEO_VIEW',
                          street_url,
                          video_url,
                          property_id
                        );
                      }}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="Video"
                        style={{
                          objectPosition: '-316px 0px',
                          objectFit: 'cover',
                          width: '20px',
                          height: '38px',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid
                    style={{
                      cursor: 'pointer',
                      marginBottom: '-15px',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      access_token
                        ? setWishlistFlagFunc(property_id)
                        : saveDatainSession(
                            investment_index,
                            cityName,
                            property_id,
                            projectName,
                            bhk_types,
                            developer_name,
                            livability_index,
                            location,
                            module_type,
                            Images,
                            priceToshow,
                            street_url,
                            video_url,
                            p_we_recommend_count,
                            squarFit,
                            minPrice,
                            maxPrice,
                            minCarpetArea,
                            furnishingStatus
                          );
                    }}>
                    <img
                      src="/static/images/home-icons.svg"
                      alt="Favourite"
                      style={{
                        objectPosition: wishlist_flag
                          ? '-281px 0px'
                          : '-252px 0px',
                        objectFit: 'cover',
                        width: '26px',
                        height: '38px',
                        position: 'relative',
                        top: '0px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={classes.cardBody}>
            <div
              className={classes.phoneIcon}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                scheduleTourClick(property_id, property_name);
              }}>
              <img
                src="/static/images/home-icons.svg"
                alt="metro-location"
                className={classes.phoneIconData}
                // style={{
                //   objectPosition: '-437px 5px',
                //   objectFit: 'cover',
                //   width: '21px',
                //   height: '30px',
                // }}
              />
            </div>
            <Grid container>
              <Grid className={classes.titleData} size={12}>
                {title}
                {details}
              </Grid>

              {/* <Grid xs={12} item className={classes.location}>
                {details}
              </Grid> */}
              <Grid size={12} >
              {dealOffer && (
                <MoreDetailTooltip
                  title={dealOffer}
                  open={dealPopup}
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow
                  }}>
                  <Grid
                    className={classes.mobDealWeek}
                    style={{ marginRight: '6px', marginBottom: '9px' }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDealPopup(!dealPopup);
                      setTimeout(() => setDealPopup(false), 2000);
                    }}
                    size={3}>
                    {/* <div className={classes.mobDealWeek}> */}
                    <img
                      src="/static/images/home-icons.svg"
                      alt="deal"
                      style={{
                        objectPosition: '-700px 0px',
                        objectFit: 'cover',
                        width: '68px',
                        height: '30px',
                      }}
                    />

                    {/* </div> */}
                  </Grid>
                </MoreDetailTooltip>
              )}

              {offer && (
                <MoreDetailTooltip
                  ref={dealTooltipRef}
                  title={offer}
                  open={offerPopup}
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow
                  }}>
                  <Grid
                    className={classes.mobDealWeek}
                    style={{ marginBottom: '9px' }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOfferPopup(!offerPopup);
                      setTimeout(() => {
                        setOfferPopup(false);
                      }, 2000);
                    }}
                    size={3}>
                    <img
                      src="/static/images/home-icons.svg"
                      alt="offer"
                      style={{
                        objectPosition: '-631px 0px',
                        objectFit: 'cover',
                        width: '70px',
                        height: '30px',
                      }}
                    />
                  </Grid>
                </MoreDetailTooltip>
              )}
              </Grid>

              <div className={classes.cardDetails}>
                {priceTag && (
                  <Grid container className={classes.flatInfo}>
                    <Grid size={1}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="rupee"
                        style={{
                          objectPosition: '-29px 9px',
                          objectFit: 'cover',
                          width: '14px',
                          height: '28px',
                          marginTop: '-7px',
                        }}
                      />
                    </Grid>
                    <Grid className={classes.flatsubhead} size={11}>
                      {priceTag}
                    </Grid>
                  </Grid>
                )}
                {bhk && (
                  <Grid size={12}>
                    <Grid>
                      <div className={classes.flatInfo1}>
                        <Grid container>
                          {/* <Grid  xs={1}> */}
                            <img
                              src="/static/images/home-icons.svg"
                              alt="bhk"
                              style={{
                                objectPosition: '0px 0px',
                                objectFit: 'cover',
                                width: '15px',
                                height: '25px',
                                marginBottom: '-10px',
                              }}
                            />
                          {/* </Grid> */}
                          {/* <Grid  xs={11} className={classes.flatsubhead}> */}
                            {bhk}
                          {/* </Grid> */}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                )}
                {sqft && (
                  <Grid size={12}>
                    <div className={classes.flatInfo}>
                      <Grid container>
                        {/* <Grid  xs={1}> */}
                          <img
                            src="/static/images/home-icons.svg"
                            alt="carpet-area"
                            style={{
                              objectPosition: '-48px 0px',
                              objectFit: 'cover',
                              width: '18px',
                              height: '28px',
                            }}
                          />
                        {/* </Grid> */}
                        {/* <Grid  xs={11}> */}
                          {sqft?.props?.text !== '0 to 0 SqFt' ? (
                            <span className={classes.flatsubhead}>{sqft}</span>
                          ) : (
                            <span
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                scheduleTourClick(
                                  property_id,
                                  projectName,
                                  developer_name,
                                  false,
                                  true
                                );
                              }}>
                              Area On Request
                            </span>
                          )}
                        {/* </Grid> */}
                      </Grid>
                    </div>
                  </Grid>
                )}
              </div>
            </Grid>
            <Grid container justifyContent="flex-start"></Grid>
            <Grid
              style={{ marginBottom: '10px' }}
              container
              justifyContent="space-between">
              <Grid></Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </StyledCommonCardContainer>)
  );
};

export default MobResidentialProjectCard;
