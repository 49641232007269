import React, { useState, useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import {
  getRecentlyPostedProjects,
  nextRecentlyPostedProjects,
  addWishlist,
} from '../../../redux/actions/main';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../../packages/common-header-text';
import {
  slugify,
  gotoPropertyDetailsPage,
  getSearchPageURL,
  searchURLType,
} from '../../util';
import FallbackMobLivability from '../Common/FallbackMobLivability';
import ClassNames from 'classnames';
import MobHorizontalScroll from '../../packages/mob-horizontal-scroll';
import useAccessToken from '../../packages/use-access-token';
import useUserInfo from '../../packages/use-user-info';
import ViewAll from '@/components/Desktop/Common/ViewAll';
import MobRecentlyPostCard from '@/components/packages/mob-recently-post-card';
import Variables from 'styles/variables.module.scss';
import styles from './mobileHomepage.module.scss';
import Skeleton from '@mui/material/Skeleton';

const highlightedWord = word => {
  return <span className={styles.dealWeekTitle}>{word}</span>;
};

// All items component
// Important! add unique key
export const Menu = (
  list,
  loading,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
  toggleWishlist
) =>
  list &&
  list.length > 0 &&
  list
    .filter(project => {
      if (project) {
        return true;
      }
    })
    .map((el, index) => {
      return (
        <MenuItem
          text={el}
          key={index}
          cardIndex={index}
          loading={loading}
          open_modal={open_modal}
          propertyDetailsClick={propertyDetailsClick}
          propertyDetailsGallery={propertyDetailsGallery}
          module_type={module_type}
          cachedProject={cachedProject}
          wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
          addwishlisttrigger={addwishlisttrigger}
          setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
          deletecachedpropertytrigger={deletecachedpropertytrigger}
          toggleWishlist={toggleWishlist}
        />
      );
    });

const MenuItem = ({
  text,
  cardIndex,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
  toggleWishlist,
}) => {
  const {
    pId,
    projectImage,
    projectName,
    weRecommendCount,
    developerName,
    localityName,
    cityName,
    streetView,
    videoLink,
    maxCarpetArea,
    minCarpetArea,
    configurationTypeCount,
    rentAmount,
    furnishingStatus,
    buildingName,
    wishlists,
    liveabilityIndexCount,
    bestForInvestmentCount,
    maxPrice,
    minPrice,
    propetyBuyOrRent,
  } = text;

  const bhk_types = Object.keys(configurationTypeCount)
    .map(key => `${configurationTypeCount[key]} ${key}`)
    .join(', ');

  const priceToShow =
    maxPrice !== minPrice ? `${minPrice} - ${maxPrice}` : minPrice;
  const isResale = propetyBuyOrRent === 'resale';
  const formattedBuildingName = (buildingName || projectName)
    .toLowerCase()
    .replace(/\s/g, '-');
  const propertyTitle = `${maxCarpetArea
    .replace(/,/g, '')
    .toLowerCase()}-sq-ft-apartment-for-rent-${formattedBuildingName}`;
  const priceTagContent =
    rentAmount && parseInt(rentAmount) ? (
      <CommonHeaderText
        variant="h4"
        text={`₹ ${rentAmount}`}
        style={{
          fontSize: '13px',
          color: Variables.darkColor,
          fontWeight: 'bold',
        }}
      />
    ) : (
      <div onClick={() => open_modal(pId, projectName)}>
        <h4 className={styles.priceTag}>Price On Request</h4>
      </div>
    );

  return (
    (<MobRecentlyPostCard
      cardIndex={cardIndex}
      isResale={isResale}
      maxCarpetArea={maxCarpetArea}
      setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
      deletecachedpropertytrigger={deletecachedpropertytrigger}
      addwishlisttrigger={addwishlisttrigger}
      wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
      cachedProject={cachedProject}
      projectName={projectName}
      livability_index={liveabilityIndexCount}
      investment_index={bestForInvestmentCount}
      locationwithoutCityName={localityName}
      wishlists={wishlists}
      maxPrice={module_type === 'buy' ? maxPrice : rentAmount}
      minPrice={module_type === 'buy' ? minPrice : rentAmount}
      priceToshow={priceToShow}
      toggleWishlist={toggleWishlist}
      developer_name={`By ${developerName}`}
      bhk_types={bhk_types}
      p_we_recommend_count={weRecommendCount}
      property_id={pId}
      propertyDetailsClick={propertyDetailsClick}
      location={localityName}
      propertyDetailsGallery={propertyDetailsGallery}
      property_name={propertyTitle}
      buildingName={formattedBuildingName}
      scheduleTourClick={open_modal}
      rentAmount={rentAmount}
      furnishingStatus={furnishingStatus}
      title={<h4 className={styles.projectTitle}>{projectName}</h4>}
      subheader={<h5 className={styles.cityName}>{`by ${developerName}`}</h5>}
      sqft={<h5 className={styles.cityName}>{`${minCarpetArea} SqFt`}</h5>}
      details={
        <h5 className={styles.cityName}>{`${localityName}, ${cityName}`}</h5>
      }
      star_rating={weRecommendCount}
      bhk={<h5 className={styles.cityName}>{bhk_types}</h5>}
      price={
        <Grid container>
          <Grid>
            <CommonHeaderText
              text={
                <span style={{ paddingLeft: '3px' }}>
                  {'₹ '}
                  <span
                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      paddingLeft: '9px',
                      color: Variables.darkColor,
                    }}>
                    {rentAmount}
                  </span>
                  <span style={{ fontSize: '14px', fontWeight: '400' }}>
                    {'Rent'}
                  </span>
                </span>
              }
              variant="h4"
              style={{
                color: Variables.darkColor,
                fontSize: '18px',
                fontWeight: 700,
                fontFamily: 'Open Sans',
              }}
            />
          </Grid>
        </Grid>
      }
      priceTag={priceTagContent}
      Images={projectImage}
      ImageWidth="350px"
      ImageHeight="200px"
      street_url={streetView}
      video_url={videoLink}
      module_type={slugify(module_type)}
      cityName={slugify(cityName)}
      minCarpetArea={minCarpetArea}
    />)
  );
};

const LivabilitySection = props => {
  let { module_type, data } = props;
  const {
    forwardedRef,
    open_modal,
    propertyDetailsGallery,
    city,
    cachedProject,
    callWishlistApi,
    wishlistPropertyDeletedCall,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
  } = props;

  const { projects, loading } =
    props.apiData.projects.length > 0 ? props.apiData : data;

  module_type = module_type || 'buy';
  const [access_token] = useAccessToken();
  const [user] = useUserInfo();
  const toggleWishlist = propertyid => {
    props.addWishlist({
      data: {
        property_id: propertyid,
        user_id: user?.id,
        module_type: module_type,
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      callBack: callWishlistApi,
    });
    wishlistPropertyDeletedCall();
  };
  const propertyDetailsClick = (
    property_id,
    property_name,
    location,
    bhkTypes
  ) => {
    gotoPropertyDetailsPage({
      module_type: module_type,
      city_name: city?.name,
      property_name: property_name,
      property_id: property_id,
      localityName: location,
      bhkTypes: bhkTypes,
    });
  };
  const firstmenu = Menu(
    projects,
    loading,
    open_modal,
    propertyDetailsClick,
    propertyDetailsGallery,
    module_type,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    toggleWishlist
  );

  const [limit] = useState(16);

  useEffect(
    () => {
      let mounted = false;
      if (!mounted) {
        let userId = user?.id;
        props.getRecentlyPostedProjects({
          city_id: city?.id,
          skip: 0,
          limit: limit,
          module_type: module_type,
          section: 'recent',
          user_id: userId,
        });
      }
      return () => {
        mounted = true;
      };
    },
    [
       city,
      // module_type,
      // user,
      // wishlistpropertydeletedtrigger,
      // addwishlisttrigger,
    ]
  );

  // Weekday Logic
  const [weekday, setweekday] = useState();
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  function getWeekBegin() {
    var now = new Date();
    var next = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + (7 - now.getDay())
    );
    return next;
  }
  //convert date into required format
  function convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);

    return [day, months[mnth - 1], date.getFullYear()].join(' ');
  }
  useEffect(() => {
    var firstDay = getWeekBegin();
    setweekday(convert(firstDay));
  }, [weekday]);
  return (
    projects.length !== 0 && 
    (<section
      ref={forwardedRef}
      style={{ marginTop: '20px' }}
      className="main-section-rental-mob">
      <div className="container">
        <Grid
          justifyContent="space-between"
          alignItems="flex-start"
          container
          className="heading-section">
          <Grid>
            {!projects || (projects.length === 0 && loading) ? (
              <Skeleton
                animation="wave"
                variant="rect"
                width={200}
                height={10}
              />
            ) : (
              <CommonHeaderText
                text={
                  // 'Residential Projects in ' + capitalizeFirstLetter(city?.name)
                  <span>{highlightedWord('Recently Posted Properties')} </span>
                }
                size="16px"
                color="#000"
                style={{
                  fontFamily: 'Open Sans',
                  fontWeight: 'bold ',
                  lineHeight: '1',
                  fontSize: '16px',
                  color: Variables.primaryColor,
                  letterSpacing: '0em !Important',
                }}
              />
            )}
            {!projects || (projects.length === 0 && loading) ? (
              <Skeleton
                animation="wave"
                variant="rect"
                width={200}
                height={10}
              />
            ) : (
              <CommonHeaderText
                // text="Best Livability Rating Projects"
                text={<span>In {city.name}</span>}
                size="13px"
                color="#000"
                style={{
                  fontFamily: 'Open Sans, sans-serif',
                  fontWeight: '700',
                  lineHeight: '1.8',
                  fontSize: '13px',
                  color: '#606060',
                  textTransform: 'capitalize',
                }}
              />
            )}
          </Grid>
          {projects.length > 3 && (
          <Grid>
            <ViewAll
              text="View All"
              url={getSearchPageURL(
                searchURLType.recommendedProjects,
                module_type,
                city?.name
              )}
            />
          </Grid> 
          )}
        </Grid>
      </div>
      <div
        className={ClassNames(['container', 'card-section'])}
        style={{
          position: 'relative',
        }}>
        {!projects || projects.length == 0 ? (
          <FallbackMobLivability />
        ) : (
          <MobHorizontalScroll data={firstmenu} />
        )}
      </div>
    </section>)
  );
};

const MobRecentlyPost = handleViewport(LivabilitySection, {
  rootMargin: '-1.0px',
});

const mapStateToProps = state => ({
  apiData: state.recentlyPostedReducer,
});

const mapDispatchToProps = {
  getRecentlyPostedProjects,
  nextRecentlyPostedProjects,
  addWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobRecentlyPost);
