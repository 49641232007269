import React, { useRef, useState, useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import {
  getLivabilityProjects,
  updateLivabilityProjects,
  nextLivabilityProjects,
  addWishlist,
} from '../../../redux/actions/main';
import FallbackCard from '../Common/FallbackCard';
import { connect } from 'react-redux';
import ViewAll from '../Common/ViewAll';
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../../packages/common-header-text';
import HorizontalScrollButtons from '../Common/HorizontalScrollButtons';
import HorizontalScroll from '../../packages/horizontal-scroll';
import {
  capitalizeFirstLetter,
  slugify,
  gotoPropertyDetailsPage,
  getSearchPageURL,
  searchURLType,
} from '../../util';
import debounce from 'lodash/debounce';
import useAccessToken from '../../packages/use-access-token';
import PropertyCard from '../../packages/property-card';
import Variables from 'styles/variables.module.scss';
import classes from './livability.module.scss';
import HomepageCityTab from './HomepageCityTab';
import classNames from 'classnames';

export const Menu = (
  list,
  loading,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger
) =>
  list &&
  list.length > 0 &&
  list
    .filter(project => {
      if (project) {
        return true;
      }
    })
    .map((el, index) => {
      return (
        <MenuItem
          text={el}
          key={index}
          cardIndex={index}
          loading={loading}
          open_modal={open_modal}
          propertyDetailsClick={propertyDetailsClick}
          propertyDetailsGallery={propertyDetailsGallery}
          toggleWishlist={toggleWishlist}
          openLoginModal={openLoginModal}
          module_type={module_type}
          cachedProject={cachedProject}
          wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
          addwishlisttrigger={addwishlisttrigger}
          setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
          deletecachedpropertytrigger={deletecachedpropertytrigger}
        />
      );
    });

const MenuItem = ({
  text,
  open_modal,
  cardIndex,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
}) => {
  const {
    pId,
    projectImage,
    projectName,
    weRecommendCount,
    developerName,
    maxPrice,
    minPrice,
    liveabilityIndexCount,
    localityName,
    cityName,
    bestForInvestmentCount,
    streetView,
    videoLink,
    minCarpetArea,
    maxCarpetArea,
    configurationTypeCount,
    wishlists,
    dealOffer,
    offer,
    furnishingStatus,
    propetyBuyOrRent,
  } = text;
  const [imageLoaded, setImageLoaded] = useState(cardIndex < 3);
  const imgRef = useRef(null);
  const bhk_type_label = Object.keys(configurationTypeCount);
  const bhk_types = bhk_type_label
    .map(data => {
      return configurationTypeCount[data].toString() + ' ' + data;
    })
    .toString();


    useEffect(() => {
      if (cardIndex < 3) return; // Skip observer for the first 3 images
  
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setImageLoaded(true);
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
      );
  
      if (imgRef.current) observer.observe(imgRef.current);
  
      return () => observer.disconnect();
    }, []);
  return (
    <>
      {/*  <Suspense> */}
      <div ref={imgRef}>
      <PropertyCard
        cardIndex={cardIndex}
        isResale={propetyBuyOrRent === 'resale'}
        maxCarpetArea={maxCarpetArea}
        setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
        deletecachedpropertytrigger={deletecachedpropertytrigger}
        addwishlisttrigger={addwishlisttrigger}
        wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
        cachedProject={cachedProject}
        property_id={pId}
        projectName={projectName}
        property_name={projectName.replace(' ', '-').toLowerCase()}
        propertyDetailsClick={propertyDetailsClick}
        propertyDetailsGallery={propertyDetailsGallery}
        scheduleTourClick={open_modal}
        livability_index={liveabilityIndexCount}
        investment_index={bestForInvestmentCount}
        location={localityName + ', ' + cityName}
        locationwithoutCityName={localityName}
        sqft={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
        rootStyle={{
          fontFamily: 'Open Sans',
          marginRight: '0px',
          marginTop: '38px',
          position: 'relative',
          bottom: '28px',
        }}
        images={imageLoaded ? projectImage || [] : []}
        p_project_name={
          <div
            style={{
              color: '#EE6A29',
              fontSize: '18px !important',
              fontWeight: 700,
              fontFamily: 'Open Sans',
            }}>
            {projectName.length > 31 ? (
              // <MoreDetailTooltip
              //   title={projectName}
              //   PopperProps={{
              //     popperOptions: {
              //       modifiers: {
              //         offset: {
              //           enabled: true,
              //           offset: '-80px, -15px',
              //         },
              //       },
              //     },
              //   }}
              //   arrow>
              //   <CommonHeaderText text={projectName} variant="h4" />
              // </MoreDetailTooltip>
              <></>
            ) : (
              <CommonHeaderText text={projectName} variant="h4" />
            )}
          </div>
        }
        developer_name={
          <CommonHeaderText text={`By ` + developerName} variant="h5" />
        }
        p_we_recommend_count={weRecommendCount}
        street_url={streetView}
        video_url={videoLink}
        price={
          minPrice && maxPrice && parseInt(minPrice) && parseInt(maxPrice) ? (
            <Grid container>
              <Grid size={1}>
                <img
                  src="/static/images/home-icons.svg"
                  alt="rupee"
                  style={{
                    objectPosition: '-29px 13px',
                    objectFit: 'cover',
                    width: '14px',
                    height: '28px',
                    marginTop: '-9px',
                  }}
                />
              </Grid>
              <Grid
                className={classes.priceContent}
                style={{ marginLeft: '-3px' }}>
                <>
                  {maxPrice !== minPrice
                    ? minPrice + ' - ' + maxPrice
                    : minPrice}
                </>
              </Grid>
            </Grid>
          ) : (
            <div
              className="cardNotClicked"
              onClick={event => {
                event.preventDefault(); // Prevent the default click behavior
                open_modal(pId, projectName, false, true);
              }}>
              <span
                className="cardNotClicked"
                style={{
                  color: Variables.darkColor,
                  fontSize: '18px',
                  fontWeight: 700,
                  fontFamily: 'Open Sans',
                  marginBottom: '3px',
                }}>
                Price On Request
              </span>
            </div>
          )
        }
        bhk_types={bhk_types}
        wishlists={wishlists}
        toggleWishlist={toggleWishlist}
        openLoginModal={openLoginModal}
        module_type={slugify(module_type)}
        cityName={slugify(cityName)}
        offer={offer}
        dealOffer={dealOffer}
        dealAndOfferTooltip={true}
        priceToshow={
          maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
        }
        maxPrice={maxPrice}
        minPrice={minPrice}
        minCarpetArea={minCarpetArea}
        furnishingStatus={furnishingStatus}
      />
      </div>
      {/* </Suspense> */}
    </>
  );
};

const LivabilitySection = props => {
  let { data, module_type } = props;
  const {
    // inViewport,
    forwardedRef,
    open_modal,
    propertyDetailsGallery,
    openLoginModal,
    city: cityData,
    cachedProject,
    callWishlistApi,
    wishlistPropertyDeletedCall,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    user,
    currentRoute,
  } = props;
  const [city, setCity] = useState(cityData);
  const [access_token] = useAccessToken();
  module_type = module_type ?? 'buy';

  const { projects, loading } = props.apiData ??
    data ?? { projects: [], loading: false };

  const componentRef = useRef();
  const [weekday, setweekday] = useState();
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  const propertyDetailsClick = (
    property_id,
    property_name,
    locationwithoutCityName
  ) => {
    gotoPropertyDetailsPage({
      module_type: module_type,
      city_name: city?.name,
      property_name: property_name,
      property_id: property_id,
      localityName: locationwithoutCityName,
    });
  };

  const toggleWishlist = propertyid => {
    props.addWishlist({
      data: {
        property_id: propertyid,
        user_id: user?.id,
        module_type: module_type,
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      callBack: callWishlistApi,
    });
    wishlistPropertyDeletedCall();
  };

  const firstmenu = Menu(
    projects,
    loading,
    open_modal,
    propertyDetailsClick,
    propertyDetailsGallery,
    toggleWishlist,
    openLoginModal,
    module_type,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger
  );

  const highlightedWord = word => {
    return <span className={classes.highlightWord}>{word}</span>;
  };

  const [limit] = useState(6);
  const [places, setplaces] = useState(0);
  const livabilityProjects = debounce(
    (get, moduletype, global_selected_city_id, skips, userId) => {
      const queryParams = {
        city_id: global_selected_city_id,
        skip: skips,
        limit: limit,
        module_type: moduletype,
        section: 'dealoftheweek',
        access_token: access_token,
        user_id: userId,
      };

      if (get) {
        props.getLivabilityProjects(queryParams);
      } else {
        props.nextLivabilityProjects(queryParams);
      }
    }
  );
  // const [firstMount, setfirstMount] = useState(false);
  useEffect(() => {
    // if (!firstMount) {
    //   setfirstMount(true);
    //   return;
    // }
    let mounted = false;
    let userId = user?.id;
    if (!mounted) {
      setplaces(0);
      livabilityProjects(true, module_type, city?.id, 0, userId);
    }
    return () => {
      mounted = true;
    };
  }, [city, module_type]);
  useEffect(() => {
    setCity(cityData);
  }, [cityData]);
  //get first day of week
  function getWeekBegin() {
    let now = new Date();
    let next = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + (7 - now.getDay())
    );
    return next;
  }

  useEffect(() => {
    //convert date into required format
    function convert(str) {
      let date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      let endsOnDate = [day, months[mnth - 1], date.getFullYear()].join(' ');
      return endsOnDate;
    }

    let firstDay = getWeekBegin();
    let endsForDay = convert(firstDay);
    setweekday(endsForDay);
  }, [weekday]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      updateLivabilityProjects(projects);
    }

    return () => {
      mounted = true;
    };
  }, [projects]);

  const loadPrevProjects = () => {
    setplaces(prev => prev - 1);
    componentRef?.current?.handleArrowClick();
  };

  const loadNextProjects = () => {
    setplaces(prev => prev + 1);
    componentRef?.current?.handleArrowClickRight();
  };

  const handleCitySelect = city => {
    setCity(city);
  };

  return projects.length > 0 ? (
    <div className={classes.livabilityData}>
      <section
        ref={forwardedRef}
        className="main-section"
        style={{
          marginTop: '37px',
        }}>
        <div className="container">
          <Grid container>
            <Grid
              justifyContent="flex-start"
              alignItems="center"
              container
              // className={classes.livabilityHeading}
              className={classNames(classes.livabilityHeading, {
                [classes.noBorderRight]: currentRoute === '/',
              })}
              size={9}>
              <Grid container size={12}>
                <Grid>
                  <CommonHeaderText
                    text={
                      <>
                        {highlightedWord(
                          currentRoute === '/'
                            ? 'Deal of The Week'
                            : `Deal of The Week in ${capitalizeFirstLetter(
                                city?.name || ''
                              )}`
                        )}

                        {/* {capitalizeFirstLetter(city?.name)} */}
                      </>
                    }
                    variant="h1"
                  />
                  <CommonHeaderText
                    text={<> Ends on {weekday}</>}
                    className={classes.subheading}
                  />
                </Grid>
                {currentRoute == '/' ? (
                  <Grid>
                    <HomepageCityTab
                      oncitySelect={handleCitySelect}
                      globalCity={city}
                    />
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            {projects.length > 3 && (
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginLeft: '-8px',
                  marginTop: '-20px',
                }}
                size={3}>
                {city?.name.toLowerCase() === 'all' ? (
                  ' '
                ) : (
                  <div
                    style={{
                      paddingRight: '15px',
                      borderRight: '2px solid #17171721',
                    }}>
                    <ViewAll
                      text="View All"
                      url={getSearchPageURL(
                        searchURLType.dealOfWeek,
                        module_type,
                        city?.name
                      )}
                    />
                  </div>
                )}
                <div
                  style={{
                    paddingLeft: '15px',
                  }}>
                  <HorizontalScrollButtons
                    loadPrevProjects={loadPrevProjects}
                    loadNextProjects={loadNextProjects}
                    disableNext={projects.length <= (places + 1) * 3}
                    disablePrev={places <= 0}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            className="card-section"
            style={{ marginTop: '16px' }}>
            <Grid size={12}>
              {projects.length < 0 || loading ? (
                <>
                  <Grid
                    spacing={2}
                    container
                    justifyContent="center"
                    alignitems="center">
                    <Grid size={4}>
                      <FallbackCard />
                    </Grid>
                    <Grid size={4}>
                      <FallbackCard />
                    </Grid>
                    <Grid size={4}>
                      <FallbackCard />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <HorizontalScroll
                  itemClass="property-card-item"
                  componentRef={componentRef}
                  data={firstmenu}
                  dragging={false}
                />
              )}
            </Grid>
          </Grid>
          {/* )} */}
        </div>
      </section>
    </div>
  ) : (
    ''
  );
};

const Livability = handleViewport(LivabilitySection, { rootMargin: '-1.0px' });

const mapStateToProps = state => ({
  apiData: state.livabilityReducer,
  headersApiData: state.headerReducer,
  whishlistApiData: state.addWishlistReducer,
});

const mapDispatchToProps = {
  getLivabilityProjects,
  nextLivabilityProjects,
  addWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(Livability);
