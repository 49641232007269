  import React, { useState, useEffect, useRef } from 'react';
  import Grid from '@mui/material/Grid2';
  import { Box } from '@mui/material';

  const MobWhyInfoSection = () => {
    const containerRef = useRef(null);
    const [slideIndex, setSlideIndex] = useState(0);

    const rootStyles = {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft:'15%',
      paddingRight:'20px',
      display:'flex',
      '@media (min-width: 768px)': {
        paddingTop:'10px',
        marginBottom:'20px',
        paddingLeft:'25%',
      },

      '@media (min-width:1024px)': {
        paddingTop:'30px',
        marginBottom:'20px',
      },

      '@media (min-width:1030px) and (max-width:1057px)': {
        paddingTop:'30px',
        marginBottom:'20px',
        marginTop:'-50px',
      },

      '& .whyList': {
        display: 'flex',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        width: '80%',
        position: 'relative',
        marginLeft: '17px',
        marginTop:'8px',
      },
      '& .scrollContent': {
        display: 'flex',
        animation: 'scrolling 60s linear infinite',
      },

      '& .slideFromRight': {
        animationName: 'slideFromRight',
        animationDuration: '6s', // Adjust to the desired time for the sliding
        animationTimingFunction: 'ease-in-out', // Smooth easing for the animation
        animationIterationCount: 'infinite', 
        animationDelay: '6s',
      },
      '@keyframes slideFromRight': {
        from: {
          transform: 'translateX(100%)',
        },
        '25%': {
          transform: 'translateX(0)',  // Push content to its position
        },
        '50%': {
          transform: 'translateX(0)',  // Content stays at original position (pause)
        },
        '75%': {
          transform: 'translateX(0)',  // Keep the content still
        },
        to: {
          transform: 'translateX(-100%)',  // Slide out to the left
        },
      },
      '& .whyContent': {
        display: 'flex',
        flexShrink: 0,
        marginRight: '16px',
        alignItems: 'center',
        animationDelay: '6s',
        // width: '20%',
      },
      '& .whyDetailsContainer': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .whyDetailsBox': {
        borderRadius: '4px',
        padding: '0px 17px 0px 12px',
      },
      '& .whyDetailsInnerBox': {
        padding: '1px',
        '&.whyimg1': {
          '& img': {
            objectPosition: '-26px 0px',
            objectFit: 'cover',
            width: '26px',
            height: '26px',
            marginLeft: '-11px',
            marginTop:'3px'
          },
        },
        '&.whyimg2': {
          '& img': {
            objectPosition: '-63px 0px',
            objectFit: 'cover',
            width: '26px',
            height: '26px',
             marginTop:'3px'
          },
        },
        '&.whyimg3': {
          '& img': {
            objectPosition: '-99px 0px',
            objectFit: 'cover',
            width: '26px',
            height: '26px',
             marginTop:'3px'
          },
        },
        '&.whyimg4': {
          '& img': {
            objectPosition: '-134px 0px',
            objectFit: 'cover',
            width: '26px',
            height: '26px',
          },
        },
      },
      '& .whyDetailsHeading': {
        '& h5': {
          fontSize: '13px',
          lineHeight: '17px',
          '@media (min-width: 768px)': {
            fontSize: '15px',
            marginTop:'-4px',
          }
        },
      },    
      '& .whyimg': {
        position: 'absolute',
        top: '14px',
        right: '-11px',
        '& img': {
          objectPosition: '1px 0px',
          objectFit: 'cover',
          width: '10px',
          height: '20px',
          marginLeft: '-11px',
        },
      },
      '& .whyLogo': {
        justifyContent: 'center',
        position: 'relative',
        borderRight: '1px solid rgba(199,197,197, 1)',
        paddingRight: '17px',
        // width: '31%',
      },
    };

    const content = [
      {
        imgClass: 'whyimg1',
        title: 'No Brokerage. No Service Charges',
        imgSrc: '../static/images/whyiconimg.png',
      },
      {
        imgClass: 'whyimg2',
        title: 'Discounted Properties',
        imgSrc: '../static/images/whyiconimg.png',
      },
      {
        imgClass: 'whyimg3',
        title: 'Personalised Assistance',
        imgSrc: '../static/images/whyiconimg.png',
      },
      {
        imgClass: 'whyimg4',
        title: 'Interactive Streetviews',
        imgSrc: '../static/images/whyiconimg.png',
      },
    ];

    useEffect(() => {
      const timer = setInterval(() => {
        setSlideIndex((prevIndex) => (prevIndex + 1) % 4); // Cycle through content slides
      }, 2000);

      return () => clearInterval(timer); // Clean up the interval on unmount
    }, []);

    const renderSlideshow = () => (
      <div className="slideshow-container">
        {content.map((slide, index) => (
          <div
            key={index}
            className={`mySlides slideFromRight ${index === slideIndex ? 'visible' : ''}`}
            style={{ display: index === slideIndex ? 'block' : 'none' }}
          >
            <Grid className="whyContent">
              <Grid className="whyDetailsContainer">
                <Grid className="whyDetailsBox">
                  <Grid className={`whyDetailsInnerBox ${slide.imgClass}`}>
                    <img src={slide.imgSrc} alt="icon" />
                  </Grid>
                </Grid>
                <Grid className="whyDetailsHeading">
                  <h5>{slide.title}</h5>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    );

    return (
      <Box sx={{ ...rootStyles }}>
        <Grid className="whyLogo">
          <img src="https://ik.imagekit.io/sjnshacs8/propertygallery/whylogo.png" alt="whyimg" title="whyimg" height="49px" width="76px" />
          <div className="whyimg">
            <img src="../static/images/whyiconimg.png" alt="icon" />
          </div>
        </Grid>

        <Grid className="whyList" ref={containerRef}>
          {/* Slideshow */}

          {/* Duplicate content for seamless scrolling */}
          <div className="scrollContent">
            {renderSlideshow()}
          </div>
        </Grid>
      </Box>
    );
  };

  export default MobWhyInfoSection;
