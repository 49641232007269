import React, { useRef, useState, useEffect } from 'react';

import {
  getRecommendedProjects,
  nextRecommendedProjects,
  addWishlist,
} from '../../../redux/actions/main';
import handleViewport from 'react-in-viewport';
import FallbackCard from '../Common/FallbackCard';
import { connect } from 'react-redux';
import ViewAll from '../Common/ViewAll';
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../../packages/common-header-text';
import HorizontalScrollButtons from '../Common/HorizontalScrollButtons';
import HorizontalScroll from '../../packages/horizontal-scroll';
import {
  slugify,
  gotoPropertyDetailsPage,
  getSearchPageURL,
  searchURLType,
} from '../../util';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import useAccessToken from '../../packages/use-access-token';

import MoreDetailTooltip from '@/components/PageComponents/PropertyPage/components/Tooltip';
import PropertyCard from '../../packages/property-card';
import Variables from 'styles/variables.module.scss';
import classes from './recommendproject.module.scss';
import HomepageCityTab from './HomepageCityTab';
import classNames from 'classnames';

// All items component
// Important! add unique key
export const Menu = (
  list,
  loading,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger
) =>
  list &&
  list.length > 0 &&
  // list
  uniqBy(list, 'pId')
    .filter(project => {
      if (project) {
        return true;
      }
    })
    .map((el, index) => {
      return (
        <MenuItem
          cachedProject={cachedProject}
          text={el}
          key={index}
          loading={loading}
          open_modal={open_modal}
          propertyDetailsClick={propertyDetailsClick}
          propertyDetailsGallery={propertyDetailsGallery}
          toggleWishlist={toggleWishlist}
          openLoginModal={openLoginModal}
          module_type={module_type}
          wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
          addwishlisttrigger={addwishlisttrigger}
          setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
          deletecachedpropertytrigger={deletecachedpropertytrigger}
        />
      );
    });

const MenuItem = ({
  text,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type = 'buy',
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
  index
}) => {
  const {
    pId,
    projectImage,
    buildingName,
    projectName,
    weRecommendCount,
    developerName,
    maxPrice,
    minPrice,
    liveabilityIndexCount,
    localityName,
    cityName = 'mumbai',
    bestForInvestmentCount,
    streetView,
    videoLink,
    minCarpetArea,
    maxCarpetArea,
    configurationTypeCount,
    wishlists,
    furnishingStatus,
    propetyBuyOrRent,
  } = text;
  const [imageLoaded, setImageLoaded] = useState(index < 3);
  const imgRef = useRef();
  const bhk_type_label = Object.keys(configurationTypeCount);
  const bhk_types = bhk_type_label
    .map(data => {
      return configurationTypeCount[data].toString() + ' ' + data;
    })
    .toString();

   
  useEffect(() => {
    if (index < 3) return; // Skip observer for the first 3 images
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setImageLoaded(true);
          observer.disconnect(); // Stop observing after loading
        }
      },
      { threshold: 0.1 } // Trigger when at least 10% of the image is visible
    );
  
    if (imgRef.current) observer.observe(imgRef.current);
  
    return () => observer.disconnect();
  }, [index]);

  return (
    <div ref={imgRef}>
    <PropertyCard
      isResale={propetyBuyOrRent === 'resale'}
      maxCarpetArea={maxCarpetArea}
      setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
      deletecachedpropertytrigger={deletecachedpropertytrigger}
      wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
      addwishlisttrigge={addwishlisttrigger}
      cachedProject={cachedProject}
      property_id={pId}
      projectName={projectName}
      property_name={(module_type == 'rent' ? buildingName : projectName)
        .replace(' ', '-')
        .toLowerCase()}
      propertyDetailsClick={propertyDetailsClick}
      propertyDetailsGallery={propertyDetailsGallery}
      scheduleTourClick={open_modal}
      livability_inde={liveabilityIndexCount}
      investment_index={bestForInvestmentCount}
      location={localityName + ', ' + cityName}
      locationwithoutCityName={localityName}
      sqft={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
      rootStyle={{
        fontFamily: 'Open Sans',
        marginRight: '0px',
      }}
      images={imageLoaded ? projectImage : []}
      p_project_name={
        <div>
          {projectName.length > 31 ? (
            <MoreDetailTooltip
              title={projectName}
              PopperProps={{
                popperOptions: {
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: '-80px, -15px',
                    },
                  },
                },
              }}
              arrow>
              <CommonHeaderText text={projectName} variant="h4" />
            </MoreDetailTooltip>
          ) : (
            <CommonHeaderText text={projectName} variant="h4" />
          )}
        </div>
      }
      developer_name={
        <CommonHeaderText text={`By ` + developerName} variant="h5" />
      }
      p_we_recommend_count={weRecommendCount}
      street_url={streetView}
      video_url={videoLink}
      price={
        minPrice && maxPrice && parseInt(minPrice) && parseInt(maxPrice) ? (
          <Grid container>
            <Grid size={1}>
              <img
                src="/static/images/home-icons.svg"
                alt="rupee"
                style={{
                  objectPosition: '-29px 13px',
                  objectFit: 'cover',
                  width: '14px',
                  height: '28px',
                  marginTop: '-9px',
                }}
              />
            </Grid>
            <Grid style={{ marginLeft: '-3px' }}>
              <span className={classes.priceContent}>
                {maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice}
              </span>
            </Grid>
          </Grid>
        ) : (
          <div
            className="cardNotClicked"
            // onClick={() => open_modal(pId, projectName, false, true)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              open_modal(pId, projectName, false, true);
            }}>
            <span
              className="cardNotClicked"
              style={{
                color: Variables.darkColor,
                fontSize: '18px',
                fontWeight: 700,
                fontFamily: 'Open Sans',
                marginBottom: '3px',
              }}>
              Price On Request
            </span>
          </div>
        )
      }
      bhk_types={bhk_types}
      wishlists={wishlists}
      toggleWishlist={toggleWishlist}
      openLoginModal={openLoginModal}
      module_type={slugify(module_type)}
      cityName={slugify(cityName)}
      iaAmTrue={true}
      priceToshow={
        maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
      }
      maxPrice={maxPrice}
      minPrice={minPrice}
      minCarpetArea={minCarpetArea}
      furnishingStatus={furnishingStatus}
    />
    </div>
  );
};

const RecommendProjectSection = props => {
  let { module_type } = props;
  const {
    forwardedRef,
    open_modal,
    propertyDetailsGallery,
    openLoginModal,
    city: cityData,
    cachedProject,
    callWishlistApi,
    wishlistPropertyDeletedCall,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    recommendProjectData,
    user,
    currentRoute,
  } = props;
  const [city, setCity] = useState(cityData);

  const [access_token] = useAccessToken();
  module_type = module_type || 'buy';

  const [windowSize] = useState(3);
  const { projects, loading, end } = props.apiData ?? recommendProjectData;
  const [prevTrigger, setprevTrigger] = useState(true);
  const [places, setplaces] = useState(0);
  const componentRef = useRef();

  const propertyDetailsClick = (
    property_id,
    property_name,
    locationwithoutCityName
  ) => {
    gotoPropertyDetailsPage({
      module_type: module_type,
      city_name: city?.name,
      property_name: property_name,
      property_id: property_id,
      localityName: locationwithoutCityName,
    });
  };

  const toggleWishlist = propertyid => {
    props.addWishlist({
      data: {
        property_id: propertyid,
        user_id: user?.id,
        module_type: module_type,
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      callBack: callWishlistApi,
    });
    wishlistPropertyDeletedCall();
  };

  const firstmenu = Menu(
    projects,
    loading,
    open_modal,
    propertyDetailsClick,
    propertyDetailsGallery,
    toggleWishlist,
    openLoginModal,
    module_type,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger
  );

  // const classes = useStyles();

  const highlightedWord = word => {
    return <span className={classes.highlightWord}>{word}</span>;
  };

  const [limit] = useState(3);

  const recommendedProjects = debounce(
    (get, moduletype, global_selected_city_id, skips, userId) => {
      const queryParams = {
        city_id: global_selected_city_id,
        skip: get ? 0 : skips,
        limit: get ? skips : limit,
        module_type: moduletype,
        section: 'recommend',
        access_token: access_token,
        user_id: userId,
      };
      if (get) {
        props.getRecommendedProjects(queryParams);
      } else {
        props.nextRecommendedProjects(queryParams);
      }
    }
  );

  const [firstMount, setfirstMount] = useState(false);
  useEffect(() => {
    if (!firstMount) {
      setfirstMount(true);
      return;
    }
    let userId = user?.id;
    recommendedProjects(
      true,
      module_type,
      city?.id,
      projects.length + limit,
      userId
    );
  }, [city, module_type]);
  useEffect(() => {
    setCity(cityData);
  }, [cityData]);

  const loadPrevProjects = () => {
    setplaces(prev => prev - 1);
    setprevTrigger(false);
    componentRef.current.handleArrowClick();
  };

  const loadNextProjects = () => {
    setplaces(prev => prev + 1);
    if (!end) {
      recommendedProjects(
        false,
        module_type,
        city?.id,
        projects.length + limit,
        user?.id
      );
    } else {
      setprevTrigger(true);
    }
    componentRef.current.handleArrowClickRight();
  };
  const handleCitySelect = city => {
    setCity(city);
  };

  return (
    <>
      {projects.length !== 0 ? (
        <section
          ref={forwardedRef}
          className="main-section"
          style={{ marginTop: '53px' }}>
          <div className="container">
            <Grid container style={{ paddingBottom: '20px' }}>
              <Grid
                // className={classes.headingText}
                className={classNames(classes.headingText, {
                  [classes.noBorderRight]: currentRoute === '/',
                })}
                justifyContent="flex-start"
                container
                alignItems="center"
                size={9}>
                <Grid container size={12}>
                  <Grid>
                    <CommonHeaderText
                      text={
                        <>
                          {highlightedWord('Top Selling Recommended Projects')}
                        </>
                      }
                      variant="h3"
                    />
                    <CommonHeaderText text={<>Projects in high demand</>} />
                  </Grid>
                  {currentRoute == '/' ? (
                    <Grid>
                      <HomepageCityTab
                        oncitySelect={handleCitySelect}
                        globalCity={city}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {/* <Grid><HomepageCityTab  oncitySelect={handleCitySelect} globalCity={city}/></Grid> */}
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginLeft: '-8px',
                  marginTop: '-23px',
                }}
                size={3}>
                {city?.name.toLowerCase() === 'all' ? (
                  ' '
                ) : (
                  <div
                    style={{
                      paddingRight: '15px',
                      borderRight: '2px solid #17171721',
                    }}>
                    <ViewAll
                      text="View All"
                      url={getSearchPageURL(
                        searchURLType.recommendedProjects,
                        module_type,
                        city?.name
                      )}
                    />
                  </div>
                )}
                <div
                  style={{
                    paddingLeft: '15px',
                  }}>
                  <HorizontalScrollButtons
                    loadPrevProjects={loadPrevProjects}
                    loadNextProjects={loadNextProjects}
                    // disableNext={end && prevTrigger }
                    disableNext={
                      projects.length < places * windowSize ||
                      (end && prevTrigger)
                    }
                    disablePrev={places === 0}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              className="card-section"
              style={{ marginTop: '-20px' }}>
              <Grid size={12}>
                {loading && (!projects || projects.length == 0) ? (
                  <>
                    <Grid
                      spacing={2}
                      container
                      justifyContent="center"
                      alignitems="center">
                      <Grid size={4}>
                        <FallbackCard />
                      </Grid>
                      <Grid size={4}>
                        <FallbackCard />
                      </Grid>
                      <Grid size={4}>
                        <FallbackCard />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <HorizontalScroll
                    itemClass="property-card-item "
                    componentRef={componentRef}
                    data={firstmenu}
                    dragging={false}
                  />
                )}
              </Grid>
            </Grid>
            {/* )} */}
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

const RecommendProject = handleViewport(RecommendProjectSection, {
  rootMargin: '-1.0px',
});

const mapStateToProps = state => ({
  apiData: state.recommendedReducer,
  headerApiData: state.headerReducer,
});

const mapDispatchToProps = {
  nextRecommendedProjects,
  getRecommendedProjects,
  addWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendProject);
